import { ReactElement, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ctxUser } from '@/contexts/UserContext';
import { ctxNotification } from '@/contexts/NotificationCtx';
import TrackingResponseHandler from './components/tracking/TrackingResponseHandler';
import { PlutoClientException } from './PlutoClientException';

type ServerError = {
  localizedMessage: string;
  message: string;
};

export function HandleErrors({ children, logout }: { children: ReactElement; logout: () => void }) {
  const { t } = useTranslation();
  const ctxNotify = useContext(ctxNotification);
  const userCtx = useContext(ctxUser);
  const trackingResponseHandler = new TrackingResponseHandler();

  function getServerSideError(response: AxiosResponse): ServerError | undefined {
    const serverSideErrorData = response?.data as ServerError;
    const localizedMessage = serverSideErrorData?.localizedMessage;
    if (localizedMessage) {
      return serverSideErrorData;
    }
    return undefined;
  }

  function createClientExceptionByAxiosError(error: AxiosError): PlutoClientException | undefined {
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      return new PlutoClientException(t('error.api.err_timeout'), error.request);
    }

    if (!navigator.onLine) {
      return new PlutoClientException(t('error.api.err_network'), error.request);
    }

    if (error.response) {
      const serverSideError = getServerSideError(error.response);
      if (serverSideError !== undefined) {
        if (serverSideError.message) {
          console.warn(serverSideError.message);
        }
        return new PlutoClientException(serverSideError.localizedMessage, error.request);
      }

      const statusCode = error.response.status;
      if (statusCode == 403) {
        return new PlutoClientException(t('error.api.err_forbidden'), error.request);
      }

      if (statusCode >= 400) {
        return new PlutoClientException(t('error.api.err_network'), error.request);
      }
    }

    return undefined;
  }

  const handleTrackingError = useCallback(
    (error: AxiosError) => {
      const isTrackingUrl = error.config?.url?.includes('positions/tracking');
      if (!isTrackingUrl) {
        return false;
      }
      if (!error.response) {
        // errors without response like ERR_INTERNET_DISCONNECTED
        return true;
      }
      const status = error.response.status;
      if (status >= 400 && status < 500) {
        return false;
      }
      const errorHandled = trackingResponseHandler.handleFailedRequest();
      if (!errorHandled) {
        ctxNotify?.showNotification('error', t('error.api.failed_tracking'));
      }
      return true;
    },
    [t, ctxNotify]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function interceptorErrorHandler(error: any): Promise<unknown> {
    if (error.code === 'ERR_CANCELED') {
      return Promise.resolve({ status: 499 });
    }

    if (!axios.isAxiosError(error)) {
      return Promise.reject(error);
    }

    if (error.response) {
      const statusCode = error.response.status;

      if (statusCode == 401) {
        const isLoginUrl = error.response.config.url?.includes('login');
        if (isLoginUrl) {
          ctxNotify?.showNotification('error', t('error.api.login'));
        } else {
          if (userCtx?.isToken()) {
            // ha tokenes es lejart a session-je a pluto-serverben
            // a reload utan megprobal ujra bejelentkezni
            // ha meg jo a token akkor minden megy tovabb
            // ha lejart a token akkor hibauzenetet kap
            window.location.reload();
          } else {
            logout();
          }
        }
        return Promise.resolve({ status: 301 });
      }
    }

    if (handleTrackingError(error)) {
      return Promise.reject(error);
    }

    const clientException = createClientExceptionByAxiosError(error);
    if (clientException != undefined) {
      ctxNotify?.showNotification('error', clientException.message);
      return Promise.reject(clientException);
    }

    ctxNotify?.showNotification('error', t('error.api.unknown'));
    return Promise.reject(error);
  }

  useEffect(() => {
    axios.interceptors.request.use((config) => {
      const headerLanguage = localStorage.getItem('language');
      if (headerLanguage) {
        config.headers['Accept-language'] = headerLanguage;
      }
      config.timeout = 60000;
      return config;
    }, interceptorErrorHandler);

    axios.interceptors.response.use(function (response) {
      const isTrackingUrl = response.config.url?.includes('positions/tracking');
      if (isTrackingUrl) {
        trackingResponseHandler.successfulRequest();
      }
      return response;
    }, interceptorErrorHandler);

    return () => {
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
    };
  }, [t, ctxNotify, logout]);

  return children;
}
